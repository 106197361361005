var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reopen" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票冲红重开",
            visible: _vm.dialogVisible,
            width: "780px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "label-position": "right",
                model: _vm.formInline,
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "电子邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入电子邮箱" },
                    model: {
                      value: _vm.formInline.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "企业类型", prop: "enterpriseType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "", size: "12" },
                      model: {
                        value: _vm.formInline.enterpriseType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "enterpriseType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.enterpriseType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "", label: "全部" } }, [
                        _vm._v("全部"),
                      ]),
                      _c("el-option", { attrs: { value: 1, label: "企业" } }, [
                        _vm._v("企业"),
                      ]),
                      _c("el-option", { attrs: { value: 3, label: "个人" } }, [
                        _vm._v("个人"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Invoice_header"),
                    prop: "payerCompany",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入发票抬头" },
                    model: {
                      value: _vm.formInline.payerCompany,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "payerCompany",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.payerCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-left": "-20px" },
                  attrs: {
                    label: "纳税人识别号",
                    prop: "nsrsbh",
                    "label-width": "120px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入纳税人识别号" },
                    model: {
                      value: _vm.formInline.nsrsbh,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "nsrsbh",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.nsrsbh",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.address"),
                    prop: "ghfdz",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入地址" },
                    model: {
                      value: _vm.formInline.ghfdz,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ghfdz",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ghfdz",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "ghfdh" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入电话" },
                    model: {
                      value: _vm.formInline.ghfdh,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ghfdh",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ghfdh",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户行", prop: "ghfkhh" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入开户行" },
                    model: {
                      value: _vm.formInline.ghfkhh,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ghfkhh",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ghfkhh",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.account_number"),
                    prop: "ghfyhzh",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20, placeholder: "请输入账号" },
                    model: {
                      value: _vm.formInline.ghfyhzh,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "ghfyhzh",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.ghfyhzh",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票内容", prop: "carId" } },
                [
                  _c(
                    "span",
                    { staticStyle: { width: "202px", display: "block" } },
                    [_vm._v(_vm._s(_vm.formInline.invoiceContent))]
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票金额", prop: "carId" } },
                [
                  _c(
                    "span",
                    { staticStyle: { width: "202px", display: "block" } },
                    [_vm._v(_vm._s(_vm.formInline.billingFee + "元"))]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.submit()
                      _vm.page = 1
                    },
                  },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFrom()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }